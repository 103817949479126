import styled, { css } from 'styled-components'

import {
  TWO_COLS_MEDIA_TYPES,
  LEFT_COL_THEME_TYPES,
  RIGHT_COL_THEME_TYPES,
} from '../../constants/twoColsBoxTypes'
import media, { customMedia } from '../../common/MediaQueries'

const StyledTwoColsBoxColInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${media.phone} {
    width: 100%;
    max-width: 28rem;
  }
`

const StyledTwoColsBoxCol = styled.div`
  padding: 1.875rem;
  position: relative;

  @media ${media.phone} {
    position: static;
    width: 50%;
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }

  @media ${media.tablet} {
    padding-top: 6.25rem;
    padding-bottom: 4.375rem;
  }
`

const StyledTwoColsBoxImg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  opacity: 0.4;

  @media ${media.phone} {
    width: 50%;
    opacity: 1;
  }
`

const StyledTwoColsBoxColLeft = styled(StyledTwoColsBoxCol)`
  ${({ leftColTheme }) =>
    leftColTheme === LEFT_COL_THEME_TYPES.light &&
    css`
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.black};
    `}

  ${({ leftColTheme }) =>
    leftColTheme === LEFT_COL_THEME_TYPES.dark &&
    css`
      background: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.white};
    `}

  @media ${media.tablet} {
    padding-right: 3rem;
  }

  @media ${customMedia.inner} {
    padding-left: calc(50% + 1.875rem - ${({ theme }) =>
      theme.sizes.inner} / 2);
  }
`

const StyledTwoColsBoxColRight = styled(StyledTwoColsBoxCol)`
  ${({ rightColTheme }) =>
    rightColTheme === RIGHT_COL_THEME_TYPES.light &&
    css`
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.black};
    `}

  ${({ rightColTheme }) =>
    rightColTheme === RIGHT_COL_THEME_TYPES.dark &&
    css`
      background: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.white};
    `}

  @media ${media.tablet} {
    display: flex;
    justify-content: flex-end;
    padding-left: 3rem;
  }

  @media ${customMedia.inner} {
    padding-right: calc(50% + 1.875rem - ${({ theme }) =>
      theme.sizes.inner} / 2);
  }
`

const StyledTwoColsBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  @media ${media.phone} {
    flex-grow: 1;
    flex-direction: row;

    ${({ twoColsMediaType }) =>
      twoColsMediaType === TWO_COLS_MEDIA_TYPES.right &&
      css`
        ${StyledTwoColsBoxImg} {
          left: auto;
          right: 0;
        }
      `}

    ${({ twoColsMediaType }) =>
      twoColsMediaType === TWO_COLS_MEDIA_TYPES.left &&
      css`
        ${StyledTwoColsBoxImg} {
          left: 0;
          right: auto;
        }
      `}
  }
`

const StyledTwoColsBoxAction = styled.div`
  a {
    width: 100%;
  }

  @media ${media.phone} {
    a {
      width: auto;
    }
  }
`

const StyledTwoColsBoxContent = styled.div`
  position: relative;
  z-index: 1;

  h3 {
    margin-bottom: 1rem;

    position: relative;
    opacity: 1;
  }

  p + h3 {
    margin-top: 1.875rem;
  }

  & + ${StyledTwoColsBoxAction} {
    margin-top: 2.875rem;
  }

  @media ${media.phone} {
    flex-grow: 1;

    p + h3 {
      margin-top: 3.125rem;
    }

    & + ${StyledTwoColsBoxAction} {
      margin-top: 3rem;
    }
  }

  @media ${media.tablet} {
    & + ${StyledTwoColsBoxAction} {
      margin-top: 4.5rem;
    }
  }
`

export {
  StyledTwoColsBox,
  StyledTwoColsBoxColLeft,
  StyledTwoColsBoxColRight,
  StyledTwoColsBoxImg,
  StyledTwoColsBoxColInner,
  StyledTwoColsBoxContent,
  StyledTwoColsBoxAction,
}
