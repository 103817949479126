import styled, { css } from 'styled-components'

import { MESSAGE_TYPES } from '../../constants/messageTypes'
import theme from '../../themes/theme'

const TYPE_MAP = {
  [MESSAGE_TYPES.success]: `${theme.colors.success}`,
  [MESSAGE_TYPES.error]: `${theme.colors.error}`,
}

const StyledMessage = styled.div`
  ${({ msgType }) =>
    TYPE_MAP[msgType] &&
    css`
      color: ${TYPE_MAP[msgType]};
    `}
`

export { StyledMessage }
