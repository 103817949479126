import React from 'react'
import PropTypes from 'prop-types'

import { StyledReview, StyledReviewQuote, StyledReviewAuthor } from './styles'

const Review = ({ quote, author }) => (
  <StyledReview>
    <StyledReviewQuote>{quote}</StyledReviewQuote>
    <StyledReviewAuthor>{author}</StyledReviewAuthor>
  </StyledReview>
)

export default Review

Review.propTypes = {
  author: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
}
