import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledMediaBox,
  StyledMediaBoxInner,
  StyledMediaBoxMedia,
  StyledMediaBoxImg,
  StyledMediaBoxContent,
  StyledMediaBoxInfo,
} from './styles'
import Image from '../Image/Image'
import { BG_TYPES } from '../../constants/bgTypes'
import { fluidPropType } from '../../constants/proptypes'
import { changeLinksPath } from '../../common/utils'

const MediaBox = ({ image, info, children, hideImageOnMobile, alt }) => (
  <StyledMediaBox bg={BG_TYPES.black} color="white" pt={['1.25rem']}>
    <StyledMediaBoxInner>
      <StyledMediaBoxMedia alt={alt} hideImageOnMobile={hideImageOnMobile}>
        <StyledMediaBoxImg className="uiAnimCustomBottom">
          <Image fluid={image} />
        </StyledMediaBoxImg>
      </StyledMediaBoxMedia>
      <StyledMediaBoxContent>
        {info && (
          <StyledMediaBoxInfo
            as="h3"
            className="uiAnimCustomBottom"
            dangerouslySetInnerHTML={{
              __html: changeLinksPath(info),
            }}
          />
        )}
        <div>{children}</div>
      </StyledMediaBoxContent>
    </StyledMediaBoxInner>
  </StyledMediaBox>
)

export default MediaBox

MediaBox.propTypes = {
  fluid: PropTypes.oneOfType([fluidPropType, PropTypes.arrayOf(fluidPropType)]),
  info: PropTypes.node,
  children: PropTypes.node,
  hideImageOnMobile: PropTypes.bool,
  alt: PropTypes.bool,
}
