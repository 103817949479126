import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledField,
  StyledFieldControl,
  StyledFieldLabel,
  StyledFieldMessage,
} from '../styles'
import { StyledTextarea } from './styles'
import Message from '../../Message/Message'
import { FIELD_TYPES } from '../../../constants/fieldTypes'

const Textarea = ({
  fieldType,
  label,
  id,
  name,
  placeholder,
  validate,
  message,
  disabled,
  ...input
}) => (
  <StyledField {...{ disabled }}>
    <StyledFieldControl {...{ fieldType }} validate={!!validate}>
      <StyledTextarea
        onPaste={(e) => e.preventDefault()}
        {...{ id, name, placeholder, ...input }}
      />

      <StyledFieldLabel htmlFor={id}>{label}</StyledFieldLabel>
    </StyledFieldControl>
    {message && (
      <StyledFieldMessage>
        <Message error>{message}</Message>
      </StyledFieldMessage>
    )}
  </StyledField>
)

Textarea.defaultProps = {
  fieldType: FIELD_TYPES.light,
  disabled: false,
}

Textarea.propTypes = {
  fieldType: PropTypes.oneOf(Object.keys(FIELD_TYPES)),
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  validate: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.bool,
}

export default Textarea
