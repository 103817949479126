import React from 'react'

import { IconWrapper } from '../../common/mixins'
import theme from '../../themes/theme'

const ArrowDownIcon = () => (
  <IconWrapper width="1.83">
    <svg viewBox="0 0 12.8 7" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8 0L6.4 7 0 0h12.8z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={theme.colors.white}
      />
    </svg>
  </IconWrapper>
)

export default ArrowDownIcon
