import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import Container from '../Container'
import Inner from '../Inner'
import { StyledImage } from '../Image/styles'
import media, { customMedia } from '../../common/MediaQueries'

const StyledMediaBox = styled(Container)`
  overflow: hidden;

  @media ${media.tabletSmall} {
    min-height: 45rem;
  }
`

const StyledMediaBoxInner = styled(Inner)`
  display: flex;
  flex-direction: column;
  padding-left: 1.875rem;
  padding-right: 1.875rem;

  @media ${media.tabletSmall} {
    flex-direction: row;
  }
`

const StyledMediaBoxImg = styled.div`
  @media ${media.tabletSmall} {
    height: 100%;
  }
`

const StyledMediaBoxMedia = styled.div`
  position: relative;

  ${StyledImage} {
    position: relative;

    &::before {
      display: block;
      content: '';
      background-image: linear-gradient(
        to right,
        ${({ theme }) => rgba(theme.colors.black, 0.15)} 20%,
        transparent
      );
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  @media ${customMedia.maxTabletSmall} {
    max-height: 39.375rem;
    overflow: hidden;

    ${({ hideImageOnMobile }) =>
      hideImageOnMobile &&
      css`
        display: none;
      `}
  }

  @media ${media.tabletSmall} {
    width: 25%;
    flex-shrink: 0;

    ${({ alt }) =>
      !alt &&
      css`
        ${StyledMediaBoxImg} {
          position: absolute;
          width: calc(100% + 13rem);
          left: -13rem;
          top: 0;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          width: 43.125rem;
          height: 67.875rem;
        }

        ${StyledImage} {
          height: auto;
        }
      `}

    ${({ alt }) =>
      alt &&
      css`
        ${StyledImage} {
          min-height: 77.625rem;

          .gatsby-image-wrapper {
            height: auto;
          }
        }

        ${StyledMediaBoxImg} {
          margin-left: -14rem;
        }
      `}
  }

  @media ${media.tablet} {
    width: 33%;
  }

  @media ${customMedia.inner} {
    width: calc(100% - 47rem);
  }
`

const StyledMediaBoxContent = styled.div`
  width: 100%;
  z-index: 1;

  @media ${media.tabletSmall} {
    width: 75%;
  }

  @media ${media.tablet} {
    width: 67%;
  }

  @media ${customMedia.inner} {
    width: 47rem;
  }
`

const StyledMediaBoxInfo = styled.div`
  position: relative;
  margin-bottom: 2rem;
  z-index: 1;
  font-size: 1.25rem;
  letter-spacing: 0.35px;
  line-height: 1.6;

  strong {
    color: ${({ theme }) => theme.colors.yellow};
  }

  @media ${customMedia.maxTabletSmall} {
    padding: 1.25rem;
    background: ${({ theme }) => theme.colors.darkGrey};
    border: 1px solid ${({ theme }) => rgba(theme.colors.white, 0.1)};
    margin-top: -2rem;
  }

  @media ${media.tabletSmall} {
    margin-bottom: 2rem;
  }
`

export {
  StyledMediaBox,
  StyledMediaBoxInner,
  StyledMediaBoxMedia,
  StyledMediaBoxImg,
  StyledMediaBoxContent,
  StyledMediaBoxInfo,
}
