import styled from 'styled-components'
import { rgba } from 'polished'

import media from '../../common/MediaQueries'
import { StyledImage } from '../Image/styles'

const StyledModelProfile = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media ${media.phone} {
    flex-direction: row;
  }
`

const StyledModelContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  padding-top: 1rem;

  @media ${media.phone} {
    text-align: left;
    width: 51%;
    padding: 4.25rem 2rem 3.5rem 0;
  }
`

const StyledModelContentInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${media.phone} {
    max-width: 27.125rem;
    width: 100%;
  }
`

const StyledModelMedia = styled.div`
  @media ${media.phone} {
    max-width: 52%;
    width: 100%;
  }

  @media ${media.tablet} {
    max-width: 34.6875rem;
  }
`

const StyledModelImg = styled.div`
  position: relative;
  padding-top: 138.2%;
  height: 0;

  ${StyledImage} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media ${media.phone} {
    height: 100%;
  }
`

const StyledModelHeader = styled.div`
  margin-bottom: 0.5rem;

  @media ${media.phone} {
    margin-bottom: 2rem;
  }
`

const StyledModelDesc = styled.div`
  margin-bottom: 1.25rem;

  @media ${media.phone} {
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid ${({ theme }) => rgba(theme.colors.grey, 0.3)};
  }

  @media ${media.tablet} {
    margin-bottom: 2.5rem;
    padding-bottom: 2.5rem;
  }
`

const StyledModelDetails = styled.div`
  order: 4;
  text-align: left;
  border-top: 1px solid ${({ theme }) => rgba(theme.colors.grey, 0.3)};
  margin-top: 1.875rem;
  padding-top: 1.875rem;

  @media ${media.phone} {
    flex-grow: 1;
    margin-top: 0;
    margin-bottom: 4.25rem;
    order: 3;
    padding: 0;
    border: none;
  }
`

const StyledModelDetailsAction = styled.div`
  a {
    width: 100%;
  }

  @media ${media.phone} {
    order: 4;

    a {
      width: auto;
    }
  }
`

const StyledModelDecor = styled.p`
  display: none;

  @media ${media.desktop} {
    display: block;
    position: absolute;
    top: 3rem;
    right: -1.8125rem;
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    font-family: ${({ theme }) => theme.fontFamilies.oswald};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    line-height: 1;
    font-size: 11.25rem;
    letter-spacing: 25px;
    text-transform: uppercase;
    opacity: 0.03;
    pointer-events: none;
  }
`

export {
  StyledModelProfile,
  StyledModelContent,
  StyledModelContentInner,
  StyledModelMedia,
  StyledModelImg,
  StyledModelHeader,
  StyledModelDesc,
  StyledModelDetails,
  StyledModelDetailsAction,
  StyledModelDecor,
}
