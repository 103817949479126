import React from 'react'
import PropTypes from 'prop-types'

import Slider from '../Slider/Slider'
import Review from '../../Review/Review'
import { StyledReviewsSlider, StyledReviewsSliderDots } from './styles'
import defaultSliderOptions from '../../../constants/defaultSliderConfig'

const sliderOptions = {
  ...defaultSliderOptions,
  dots: true,
  autoplay: true,
  arrows: false,
  appendDots: (dots) => (
    <StyledReviewsSliderDots>{dots}</StyledReviewsSliderDots>
  ),
}

const ReviewsSlider = ({ reviews }) => (
  <StyledReviewsSlider>
    <Slider
      sliderOptions={sliderOptions}
      slidesArray={reviews}
      slideComponent={Review}
    />
  </StyledReviewsSlider>
)

export default ReviewsSlider

ReviewsSlider.propTypes = {
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string.isRequired,
      quote: PropTypes.string.isRequired,
    }),
  ),
}
