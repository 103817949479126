import React from 'react'
import PropTypes from 'prop-types'

import ModelDetails from '../ModelDetails/ModelDetails'
import {
  StyledModelProfile,
  StyledModelDecor,
  StyledModelContent,
  StyledModelContentInner,
  StyledModelMedia,
  StyledModelImg,
  StyledModelHeader,
  StyledModelDesc,
  StyledModelDetails,
  StyledModelDetailsAction,
} from './styles'
import Image from '../Image/Image'
import { fluidPropType } from '../../constants/proptypes'
import InternalLink from '../Buttons/InternalLink/InternalLink'
import { Heading2, Typo6 } from '../Typography/Typography'

const ModelProfile = ({ name, description, details, image, to, vipImage }) => (
  <StyledModelProfile>
    <StyledModelDecor>models</StyledModelDecor>
    <StyledModelContent className="uiAnimBottom">
      <StyledModelContentInner>
        <StyledModelHeader>
          <Heading2 as="h1">{name}</Heading2>
        </StyledModelHeader>
        <StyledModelDesc>
          {description && <Typo6 as="h2">{description}</Typo6>}
        </StyledModelDesc>
        <StyledModelDetails>
          <ModelDetails details={details} />
        </StyledModelDetails>
        <StyledModelDetailsAction>
          <InternalLink {...{ to }}>Book Now</InternalLink>
        </StyledModelDetailsAction>
      </StyledModelContentInner>
    </StyledModelContent>
    <StyledModelMedia className="uiAnimBottom">
      <StyledModelImg>
        <Image fluid={image?.fluid} objectPosition="50% 0%" {...{ vipImage }} />
      </StyledModelImg>
    </StyledModelMedia>
  </StyledModelProfile>
)

export default ModelProfile

ModelProfile.defaultProp = {
  to: '/',
}

ModelProfile.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      data: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: fluidPropType,
  vipImage: PropTypes.string,
  to: PropTypes.string.isRequired,
}
