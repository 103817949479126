import React, { useState } from 'react'
import { Form, Field } from 'react-final-form'

import {
  StyledFeedbackFormWrapper,
  StyledFeedbackFormInner,
  StyledFeedbackFormHeader,
  StyledFeedbackFormAction,
  StyledFeedbackFormSentMsg,
} from './styles'
import {
  StyledForm,
  StyledField,
  StyledFormContent,
  StyledFormLoader,
} from '../styles'
import Button from '../../Buttons/Button/Button'
import Accordion from '../../Accordion/Accordion'
import { Body, Typo3 } from '../../Typography/Typography'
import { BUTTON_TYPES } from '../../../constants/buttonTypes'
import { FIELD_TYPES } from '../../../constants/fieldTypes'
import { useFormFields } from './mock'
import { useOnSubmitHandler } from '../form'

const FeedbackForm = ({ models, currentModel }) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const handleClick = (e) => {
    e.preventDefault()
    setIsCollapsed(!isCollapsed)
  }

  const { isLoading, isSent, onSubmit, message } = useOnSubmitHandler(
    'ajax_save_feedback_submission',
  )

  const { fields, initialValues } = useFormFields(models, currentModel)

  return (
    <StyledFeedbackFormWrapper isSent={isSent}>
      <Form
        onSubmit={onSubmit}
        {...{ initialValues }}
        render={({ handleSubmit, values }) => (
          <StyledForm onSubmit={handleSubmit} isLoading={isLoading}>
            {isSent ? (
              <StyledFeedbackFormSentMsg>
                <Body opacity="1" color="success">
                  {message ? message : 'Your message was sent successfully.'}
                </Body>
              </StyledFeedbackFormSentMsg>
            ) : (
              <>
                <StyledFeedbackFormInner>
                  {fields.map(({ title, fields }, index) => (
                    <Accordion
                      key={index}
                      handleClick={handleClick}
                      isClosed={isCollapsed}
                      title={<Typo3 lineHeight="1.25">{title}</Typo3>}
                    >
                      <StyledFeedbackFormHeader>
                        <Typo3>Leave Feedback</Typo3>
                      </StyledFeedbackFormHeader>
                      <StyledFormContent>
                        {fields.map(
                          ({
                            component: Component,
                            id,
                            name,
                            title,
                            label,
                            placeholder,
                            type,
                            options,
                            disabled,
                            radioGroup,
                            validate,
                          }) => (
                            <StyledField key={name}>
                              {radioGroup ? (
                                <Component
                                  fieldType={FIELD_TYPES.light}
                                  name={name}
                                  title={title}
                                  options={options}
                                />
                              ) : (
                                <Field
                                  name={name}
                                  validate={!!validate && validate(values)}
                                >
                                  {({ input, meta }) => (
                                    <Component
                                      fieldType={FIELD_TYPES.light}
                                      id={id}
                                      label={label}
                                      title={title}
                                      placeholder={placeholder}
                                      type={type}
                                      validate={meta.touched && meta.error}
                                      options={options}
                                      disabled={disabled}
                                      {...input}
                                    />
                                  )}
                                </Field>
                              )}
                            </StyledField>
                          ),
                        )}
                      </StyledFormContent>
                    </Accordion>
                  ))}

                  <StyledFeedbackFormAction>
                    <Button
                      submit
                      type="submit"
                      aria-label="Submit"
                      btnType={BUTTON_TYPES.inverted}
                      disabled={isLoading}
                    >
                      Submit
                    </Button>
                  </StyledFeedbackFormAction>
                </StyledFeedbackFormInner>
              </>
            )}
          </StyledForm>
        )}
      />
      {isLoading && <StyledFormLoader />}
    </StyledFeedbackFormWrapper>
  )
}

export default FeedbackForm
