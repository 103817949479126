import React, { useMemo, useState, useContext } from 'react'
import { graphql } from 'gatsby'
import orderBy from 'lodash/orderBy'
import { useInView } from 'react-intersection-observer'

import Container from '../components/Container'
import Inner from '../components/Inner'
import ModelProfile from '../components/ModelProfile/ModelProfile'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import CincopaVideo from '../components/Video/CincopaVideo'
import OverlappedBox from '../components/OverlappedBox/OverlappedBox'
import TwoColsBox from '../components/TwoColsBox/TwoColsBox'
import MediaBox from '../components/MediaBox/MediaBox'
import ReviewsSlider from '../components/Sliders/ReviewsSlider/ReviewsSlider'
import OtherModelsSlider from '../components/Sliders/OtherModelsSlider/OtherModelsSlider'
import FlexGallerySlider from '../common/queries/contentBlocks/FlexGallerySlider'
import FeedbackForm from '../components/Forms/FeedbackForm/FeedbackForm'
import { Body } from '../components/Typography/Typography'
import { HEADER_TYPES } from '../constants/headerTypes'
import { URL_PATHS } from '../constants/urlPaths'
import { BG_TYPES } from '../constants/bgTypes'
import { bgTheme, generateModelDetails, buildAction } from '../common/utils'
import mapOtherModelsToList from '../common/queries/mapOtherModelsToList'
import VideoPlaceholder from '../components/VideoPlaceholder/VideoPlaceholder'
import { useVipModelDetails } from '../common/hooks/useVipModelDetails'
import AuthorizationContext from '../common/authorization/Context'

const ModelBody = ({ data }) => {
  const { isLoggedIn } = useContext(AuthorizationContext)

  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  const { acf: model, title: currentModelTitle } = data.wp.model
  const { edges: models } = data.wp.models
  const currentModelSlug = data.wp.model.slug
  const otherModelsIsAlt = models.relatedModelsSectionType

  const { data: vipData } = useVipModelDetails(data.wp.model?.id)

  const videoPlaceholder =
    model.modelVideoPreview?.imageFile?.childImageSharp.fluid
  const vipVideoPlaceholder = vipData?.acf.modelVideoPreviewVip?.mediaItemUrl

  const [videoVisible, setVideoVisible] = useState(false)

  const handleClickVideoVisible = () => setVideoVisible(true)

  const mappedModels = useMemo(
    () =>
      models
        .filter((model) => !model.node.acf.comingSoon)
        .map(({ node: { title } }) => ({
          label: title,
          value: title,
        })),
    [models],
  )

  const sortedModels = useMemo(
    () => orderBy(mappedModels, [(model) => model.label.toLowerCase()], 'asc'),
    [mappedModels],
  )

  const otherModels = useMemo(
    () =>
      models.filter(
        (model) =>
          model.node.slug !== currentModelSlug && !model.node.acf.comingSoon,
      ),
    [models, currentModelSlug],
  )

  const mappedOtherModels = mapOtherModelsToList(otherModels)

  const {
    otherModelsSliderImages,
    otherModelsSliderDetails,
  } = mappedOtherModels

  const details = generateModelDetails(model)

  const reviews = useMemo(() => {
    const feedback = vipData?.acf?.vipFeedback || model.feedback

    if (feedback) {
      return feedback.map(({ author, message }) => ({ author, quote: message }))
    }
    return []
  }, [model.feedback, vipData])

  const image = model.altImg
    ? model.altImg.imageFile?.childImageSharp
    : model.featuredImage.imageFile?.childImageSharp

  const modelProfileBg = bgTheme(model.heroBg)
  const videoBg = bgTheme(model.modelVideoBg)
  const aboutBg = bgTheme(model.aboutBg)
  const reviewsBg = bgTheme(model.feedbackBg)
  const otherModelsBg = bgTheme(model.relatedModelsBg)

  const sliderData = useMemo(() => {
    if (vipData) {
      return {
        ...model,
        vipImagesBg: vipData?.acf.vipImagesBg,
        vipImages: vipData?.acf.vipImages,
      }
    }
    if (isLoggedIn && !vipData) {
      return null
    }
    return model
  }, [vipData, model, isLoggedIn])

  let twoColsLeftAction
  let twoColsRightAction

  if (model.twocolsLeft.action.showAction !== null) {
    twoColsLeftAction = buildAction(model.twocolsLeft.action.action)
  }

  if (model.twocolsRight.action.showAction !== null) {
    twoColsRightAction = buildAction(model.twocolsRight.action.action)
  }

  return (
    <>
      <Container
        position="relative"
        overflow="hidden"
        pt={['5rem', '5.5rem', '3.75rem']}
        pb={['1.875rem', '3rem', '5.625rem']}
        bg={modelProfileBg}
        color={
          modelProfileBg === BG_TYPES.white ||
          modelProfileBg === BG_TYPES.lightGrey
            ? 'black'
            : 'white'
        }
      >
        <Inner>
          <ModelProfile
            name={data.wp.model.title}
            description={model.detailsDescription}
            {...{ details, image }}
            vipImage={vipData?.acf.vipAltImg?.mediaItemUrl}
            to={URL_PATHS.BOOKINGS}
          />
        </Inner>
      </Container>

      <Container bg={videoBg} pt={['', '1rem']}>
        <Container
          maxWidth={['', '', '80%']}
          ml="auto"
          mr="auto"
          className="uiAnimBottom"
        >
          {(videoPlaceholder || vipVideoPlaceholder) && !videoVisible ? (
            <VideoPlaceholder
              vipImage={vipVideoPlaceholder}
              image={videoPlaceholder}
              handleClickVideoVisible={handleClickVideoVisible}
            />
          ) : (
            <CincopaVideo
              id={vipData?.acf.modelVideoCincopaVip || model.modelVideoCincopa}
            />
          )}
        </Container>
      </Container>

      {!!sliderData && (
        <FlexGallerySlider
          alt
          block={sliderData}
          index={sliderData.vipImages ? 1 : 0}
        />
      )}

      {model.aboutTitle && (
        <Container bg={aboutBg}>
          <OverlappedBox
            title={model.aboutTitle}
            vipImage={vipData?.acf.vipAboutImage?.mediaItemUrl}
            image={model.aboutImage?.imageFile?.childImageSharp}
            intro={<Body>{model.aboutIntro}</Body>}
            subheading={
              <Body fontWeight="bold" opacity="1">
                {model.aboutSubheading}
              </Body>
            }
          >
            <Body>{model.aboutBody}</Body>
          </OverlappedBox>
        </Container>
      )}

      {model.twocolsLeft.firstTitle && model.twocolsRight.firstTitle && (
        <Container mt={['', '-1rem', '-2.25rem']}>
          <TwoColsBox
            vipImage={vipData?.acf.vipTwocolsImage?.mediaItemUrl}
            image={model.twocolsImage?.imageFile?.childImageSharp}
            leftColTheme={model.twocolsLeft?.style}
            leftCol={
              <>
                <Body as="h3" color="yellow" fontWeight="bold">
                  {model.twocolsLeft?.firstTitle}
                </Body>

                <Body>{model.twocolsLeft?.firstContent}</Body>

                <Body as="h3" color="yellow" fontWeight="bold">
                  {model.twocolsLeft?.secondTitle}
                </Body>

                <Body>{model.twocolsLeft?.secondContent}</Body>
              </>
            }
            leftColAction={twoColsLeftAction}
            rightColTheme={model.twocolsRight?.style}
            rightCol={
              <>
                <Body as="h3" color="yellow" fontWeight="bold">
                  {model.twocolsRight?.firstTitle}
                </Body>

                <Body>{model.twocolsRight?.firstContent}</Body>

                <Body as="h3" color="yellow" fontWeight="bold">
                  {model.twocolsRight?.secondTitle}
                </Body>

                <Body>{model.twocolsRight?.secondContent}</Body>
              </>
            }
            rightColAction={twoColsRightAction}
          />
        </Container>
      )}

      {reviews && (
        <Container
          pt={['4.375rem']}
          pb={['3.75rem', '3rem', '1.875rem']}
          bg={reviewsBg}
          color={
            reviewsBg === BG_TYPES.white || reviewsBg === BG_TYPES.lightGrey
              ? 'black'
              : 'white'
          }
          className="uiAnimBottom"
        >
          <Inner>
            <ReviewsSlider reviews={reviews} />
          </Inner>
        </Container>
      )}

      <MediaBox image={data.picModel.childImageSharp.fluid} hideImageOnMobile>
        <FeedbackForm models={sortedModels} currentModel={currentModelTitle} />
      </MediaBox>

      <Container color={otherModelsBg === BG_TYPES.black ? 'white' : 'black'}>
        <div className="uiAnimBottom">
          <div ref={ref}>
            {inView ? (
              <OtherModelsSlider
                alt={!!otherModelsIsAlt}
                bg={otherModelsBg}
                images={otherModelsSliderImages}
                details={otherModelsSliderDetails}
              />
            ) : null}
          </div>
        </div>
      </Container>
    </>
  )
}

const ModelTemplate = ({ data }) => {
  const seoData = data.wp.model.seo

  return (
    <Layout headerType={HEADER_TYPES.alt} headerDarkLogo>
      <SEO title={seoData?.title} data={seoData} />
      <ModelBody data={data} />
    </Layout>
  )
}

export default ModelTemplate

export const pageQuery = graphql`
  query($modelId: ID!) {
    picModel: file(relativePath: { eq: "pic-model.png" }) {
      childImageSharp {
        fluid(maxWidth: 690) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    wp {
      model(id: $modelId, idType: ID) {
        id
        slug
        title
        acf {
          ...modelDetailsFragment
          modelVideoBg
          modelVideoCincopa
          modelVideoPreview {
            mediaItemUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          detailsDescription
          altImg {
            mediaItemUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 555) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          aboutBg
          aboutBody
          aboutImage {
            mediaItemUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1238) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          aboutIntro
          aboutSubheading
          aboutTitle
          feedbackBg
          feedback {
            message
            date
            author
          }
          bg
          images {
            mobileX
            image {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fixed(height: 514) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
            action {
              hoverLabel
              showAction
              action {
                internalOrExternal
                external {
                  target
                  title
                  url
                }
                internal {
                  title
                  target {
                    ... on wp_Post {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Page {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Model {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                  }
                }
              }
            }
          }
          heroBg
          relatedModelsBg
          relatedModelsSectionType
          showRelatedModels
          twocolsImage {
            mediaItemUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 960) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          twocolsLeft {
            style
            firstTitle
            firstContent
            secondTitle
            secondContent
            action {
              showAction
              action {
                internalOrExternal
                external {
                  target
                  title
                  url
                }
                internal {
                  title
                  target {
                    ... on wp_Post {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Page {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Model {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                  }
                }
              }
            }
          }
          twocolsRight {
            style
            firstTitle
            firstContent
            secondTitle
            secondContent
            action {
              showAction
              action {
                internalOrExternal
                external {
                  target
                  title
                  url
                }
                internal {
                  title
                  target {
                    ... on wp_Post {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Page {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Model {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                  }
                }
              }
            }
          }
        }
        seo {
          ...seoFragment
        }
      }
      models(first: 50) {
        edges {
          node {
            id
            title
            slug
            acf {
              description
              comingSoon
            }
            featuredImage {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
