import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledOverlappedBox,
  StyledOverlappedBoxInner,
  StyledOverlappedBoxContent,
  StyledOverlappedBoxContentCol,
  StyledOverlappedBoxTitle,
  StyledOverlappedBoxIntro,
  StyledOverlappedBoxSubheading,
  StyledOverlappedBoxDesc,
  StyledOverlappedBoxMedia,
  StyledOverlappedBoxMediaCol,
} from './styles'
import Image from '../Image/Image'
import { Heading7 } from '../Typography/Typography'
import { fluidPropType } from '../../constants/proptypes'
import theme from '../../themes/theme'

const OverlappedBox = ({
  image,
  vipImage,
  title,
  intro,
  subheading,
  children,
  bgInner,
}) => (
  <StyledOverlappedBox className="uiAnimBottom">
    <StyledOverlappedBoxInner>
      <StyledOverlappedBoxContentCol bgInner={bgInner}>
        <StyledOverlappedBoxContent>
          <StyledOverlappedBoxTitle>
            <Heading7 as="h2">{title}</Heading7>
          </StyledOverlappedBoxTitle>
          <StyledOverlappedBoxIntro>{intro}</StyledOverlappedBoxIntro>
          <StyledOverlappedBoxSubheading>
            {subheading}
          </StyledOverlappedBoxSubheading>
          <StyledOverlappedBoxDesc>{children}</StyledOverlappedBoxDesc>
        </StyledOverlappedBoxContent>
      </StyledOverlappedBoxContentCol>
      <StyledOverlappedBoxMediaCol>
        <StyledOverlappedBoxMedia>
          <Image fluid={image?.fluid} vipImage={vipImage} />
        </StyledOverlappedBoxMedia>
      </StyledOverlappedBoxMediaCol>
    </StyledOverlappedBoxInner>
  </StyledOverlappedBox>
)

export default OverlappedBox

OverlappedBox.defaultProps = {
  bgInner: theme.colors.white,
}

OverlappedBox.propTypes = {
  title: PropTypes.node,
  intro: PropTypes.node.isRequired,
  subheading: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  image: fluidPropType,
  bgInner: PropTypes.string,
}
