import styled from 'styled-components'

import { transition } from '../../../common/mixins'
import media from '../../../common/MediaQueries'

const StyledReviewsSlider = styled.div`
  max-width: 56.875rem;
  margin: 0 auto;
`

const StyledReviewsSliderDots = styled.ul`
  display: flex;
  justify-content: center;

  button {
    display: flex;
    flex-shrink: 0;
    border-radius: 3.125rem;
    width: 0.625rem;
    height: 0.625rem;
    font-size: 0;
    line-height: 0;
    color: transparent;
    background: ${({ theme }) => theme.colors.yellow};
    opacity: 0.3;
    ${transition({
      duration: '100ms',
      timingFunction: 'ease-in-out',
    })};
    cursor: pointer;
  }

  li {
    display: flex;
    padding-left: 0.1875rem;
    padding-right: 0.1875rem;
    cursor: pointer;

    &:hover,
    &.slick-active {
      button {
        opacity: 1;
      }
    }

    &.slick-active {
      ${transition}
      button {
        width: 1.25rem;
      }
    }
  }

  @media ${media.phone} {
    button {
      ${transition}
    }

    li {
      &:hover {
        button {
          background: rgba(17, 17, 17, 0.9);
        }
      }
    }
  }
`

export { StyledReviewsSlider, StyledReviewsSliderDots }
