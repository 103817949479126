import styled from 'styled-components'

const StyledSelectIcon = styled.span`
  pointer-events: none;
  position: absolute;
  top: calc(3.125rem / 2);
  right: 0.75rem;
  display: flex;
  flex-shrink: 0;
  transform: translateY(-50%);

  svg {
    font-size: 0.5625rem;
  }
`

const StyledSelect = styled.select`
  height: 3.125rem;
  padding: 0.5rem 2rem 0.5rem 1rem;
  cursor: pointer;
`

const StyledSelectOption = styled.option``

export { StyledSelect, StyledSelectOption, StyledSelectIcon }
