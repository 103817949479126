import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledTwoColsBox,
  StyledTwoColsBoxImg,
  StyledTwoColsBoxColLeft,
  StyledTwoColsBoxColRight,
  StyledTwoColsBoxColInner,
  StyledTwoColsBoxContent,
  StyledTwoColsBoxAction,
} from './styles'
import {
  TWO_COLS_MEDIA_TYPES,
  LEFT_COL_THEME_TYPES,
  RIGHT_COL_THEME_TYPES,
} from '../../constants/twoColsBoxTypes'
import { fluidPropType } from '../../constants/proptypes'
import Image from '../Image/Image'

const TwoColsBox = ({
  twoColsMediaType,
  leftColTheme,
  rightColTheme,
  image,
  vipImage,
  leftCol,
  rightCol,
  leftColAction,
  rightColAction,
}) => (
  <StyledTwoColsBox twoColsMediaType={twoColsMediaType}>
    <StyledTwoColsBoxColLeft leftColTheme={leftColTheme}>
      {twoColsMediaType === TWO_COLS_MEDIA_TYPES.left && (
        <StyledTwoColsBoxImg>
          <Image fluid={image.fluid} vipImage={vipImage} />
        </StyledTwoColsBoxImg>
      )}
      <StyledTwoColsBoxColInner className="uiAnimBottom">
        {leftCol && (
          <StyledTwoColsBoxContent>{leftCol}</StyledTwoColsBoxContent>
        )}
        {leftColAction && (
          <StyledTwoColsBoxAction>{leftColAction}</StyledTwoColsBoxAction>
        )}
      </StyledTwoColsBoxColInner>
    </StyledTwoColsBoxColLeft>
    <StyledTwoColsBoxColRight rightColTheme={rightColTheme}>
      {twoColsMediaType !== TWO_COLS_MEDIA_TYPES.left && (
        <StyledTwoColsBoxImg>
          <Image fluid={image.fluid} vipImage={vipImage} />
        </StyledTwoColsBoxImg>
      )}
      <StyledTwoColsBoxColInner className="uiAnimBottom">
        {rightCol && (
          <StyledTwoColsBoxContent>{rightCol}</StyledTwoColsBoxContent>
        )}
        {rightColAction && (
          <StyledTwoColsBoxAction>{rightColAction}</StyledTwoColsBoxAction>
        )}
      </StyledTwoColsBoxColInner>
    </StyledTwoColsBoxColRight>
  </StyledTwoColsBox>
)

export default TwoColsBox

TwoColsBox.defaultProps = {
  twoColsMediaType: TWO_COLS_MEDIA_TYPES.right,
  leftColTheme: LEFT_COL_THEME_TYPES.light,
  rightColTheme: LEFT_COL_THEME_TYPES.light,
}

TwoColsBox.propTypes = {
  twoColsMediaType: PropTypes.oneOf(Object.keys(TWO_COLS_MEDIA_TYPES)),
  leftColTheme: PropTypes.oneOf(Object.keys(LEFT_COL_THEME_TYPES)),
  rightColTheme: PropTypes.oneOf(Object.keys(RIGHT_COL_THEME_TYPES)),
  leftCol: PropTypes.node,
  rightCol: PropTypes.node,
  leftColAction: PropTypes.node,
  rightColAction: PropTypes.node,
  image: fluidPropType,
  vipImage: PropTypes.string,
}
