import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'
import _noop from 'lodash/noop'
import first from 'lodash/first'

import {
  StyledRadio,
  StyledRadioTitle,
  StyledRadioContent,
  StyledRadioGroup,
  StyledRadioLabel,
  StyledRadioGroupInput,
} from './styles'
import { Body } from '../../Typography/Typography'

const RadioGroup = ({ title, name, options, onChange }) => (
  <StyledRadio role="radiogroup">
    <StyledRadioTitle>
      <Body color="white" opacity="1">
        {title}
      </Body>
    </StyledRadioTitle>

    <StyledRadioContent>
      {options.map(({ id, label, dataRef }) => (
        <StyledRadioGroup
          key={id}
          data-attr={dataRef}
          onChange={(e) => onChange(e)}
        >
          <Field
            name={name}
            type="radio"
            value={id}
            initialValue={first(options).id}
          >
            {({ input }) => (
              <StyledRadioGroupInput
                id={id}
                name={input.name}
                type="radio"
                value={id}
                checked={input.checked}
                onChange={input.onChange}
              />
            )}
          </Field>
          <StyledRadioLabel htmlFor={id}>{label}</StyledRadioLabel>
        </StyledRadioGroup>
      ))}
    </StyledRadioContent>
  </StyledRadio>
)

export default RadioGroup

RadioGroup.defaultProps = {
  onChange: _noop,
}

RadioGroup.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      dataRef: PropTypes.bool,
    }),
  ).isRequired,
  onChange: PropTypes.func,
}
