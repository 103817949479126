import { useContext } from 'react'

import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

import AuthorizationContext from '../../common/authorization/Context'

const query = gql`
  query getModel($id: ID!) {
    model(id: $id) {
      id
      acf {
        bg
        modelVideoCincopaVip
        modelVideoPreviewVip {
          mediaItemUrl
        }
        modelVideoVipMobile {
          mediaItemUrl
        }
        vipAboutImage {
          mediaItemUrl
        }
        vipAltImg {
          mediaItemUrl
        }
        vipFeedback {
          author
          date
          fieldGroupName
          message
        }
        vipTwocolsImage {
          mediaItemUrl
        }
        vipImagesBg
        vipImages {
          mobileX
          image {
            mediaItemUrl
          }
        }
        vipFeedback {
          message
          date
          author
        }
      }
    }
  }
`

export const useVipModelDetails = (id) => {
  const { isLoggedIn } = useContext(AuthorizationContext)

  const { data, loading } = useQuery(query, {
    skip: !isLoggedIn || !id,
    variables: { id },
  })

  return { loading, data: data?.model }
}
