import styled from 'styled-components'

import media from '../../common/MediaQueries'

const StyledReview = styled.blockquote`
  position: relative;
  text-align: center;
  padding-top: 2.55rem;
  padding-bottom: 4rem;

  &::before {
    z-index: 0;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    content: '“';
    color: ${({ theme }) => theme.colors.yellow};
    opacity: 0.05;
    font-size: 31.5rem;
    line-height: 1;
    transform: translateY(-50%);
  }

  @media ${media.tablet} {
    padding-top: 5.25rem;
    padding-bottom: 5rem;
  }
`

const StyledReviewQuote = styled.p`
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-bottom: 1.25rem;

  @media ${media.phone} {
    font-size: 1.5rem;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    margin-bottom: 1.875rem;
  }
`

const StyledReviewAuthor = styled.p`
  color: ${({ theme }) => theme.colors.yellow};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 0.75rem;
  letter-spacing: 7px;
  text-transform: uppercase;
`

export { StyledReview, StyledReviewQuote, StyledReviewAuthor }
