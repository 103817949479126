import styled from 'styled-components'

import {
  StyledChooseFieldGroup,
  StyledChooseFieldLabel,
  StyledChooseFieldInput,
} from '../styles'

const StyledRadio = styled.div``

const StyledRadioTitle = styled.div`
  margin-bottom: 1rem;
`

const StyledRadioLabel = styled(StyledChooseFieldLabel)`
  font-size: 0.875rem;

  &::after {
    top: 50%;
    left: 0.25rem;
    width: 0.5rem;
    height: 0.5rem;
    background: ${({ theme }) => theme.colors.yellow};
    border-radius: 3.125rem;
    transform: translateY(-50%);
  }
`

const StyledRadioGroup = styled(StyledChooseFieldGroup)``

const StyledRadioGroupInput = styled(StyledChooseFieldInput)`
  position: absolute;
  top: -100vh;
  left: -100vw;
  opacity: 0;
  height: 0;
  width: 0;
  visibility: hidden;

  &:checked + ${StyledRadioLabel}::after {
    opacity: 1;
  }
`

const StyledRadioContent = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${StyledRadioGroup} {
    &:not(:last-child) {
      margin-right: 1.875rem;
    }
  }
`

export {
  StyledRadio,
  StyledRadioTitle,
  StyledRadioContent,
  StyledRadioGroup,
  StyledRadioLabel,
  StyledRadioGroupInput,
}
