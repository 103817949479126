export const requiredValidator = () => (value) =>
  value ? undefined : 'Required'

export const emailsEqualValidator = (formValues) => (value) => {
  const hasValueError = requiredValidator()(value)
  const areEmailsEqualError =
    formValues.email === value ? undefined : 'Emails must match.'

  return hasValueError || areEmailsEqualError
}
