import styled from 'styled-components'
import { rgba } from 'polished'

import media, { customMedia } from '../../../common/MediaQueries'
import { StyledFormHeader, StyledFieldHalf, StyledFormSentMsg } from '../styles'
import {
  StyledAccordionButton,
  StyledAccordionContent,
} from '../../Accordion/styles'

const StyledFeedbackFormWrapper = styled.div`
  position: relative;

  @media ${media.phone} {
    margin-bottom: 2rem;

    ${StyledAccordionButton} {
      display: none;
    }

    ${StyledAccordionContent} {
      max-height: 100rem;
      opacity: 1;
      visibility: visible;
      overflow: visible;
      margin-top: 0;
    }
  }
`

const StyledFeedbackFormInner = styled.div`
  margin-bottom: 1.75rem;

  @media ${media.phone} {
    background: ${({ theme }) => theme.colors.darkGrey};
    border: 1px solid ${({ theme }) => rgba(theme.colors.white, 0.15)};
    padding: 1.75rem 1.5rem 1.5rem;
  }
`

const StyledFeedbackFormHeader = styled(StyledFormHeader)`
  @media ${customMedia.maxPhone} {
    display: none;
  }
`

const StyledFeedbackFieldHalf = styled(StyledFieldHalf)`
  @media ${media.phone} {
    width: calc(50% - 0.5rem);
  }
`

const StyledFeedbackFormAction = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;

  @media ${media.phone} {
    justify-content: flex-end;
  }
`

const StyledFeedbackFormSentMsg = styled(StyledFormSentMsg)`
  padding: 2rem 0 3rem 0;

  @media ${media.phone} {
    padding-top: 3rem;
  }

  @media ${media.tablet} {
    padding-top: 4rem;
  }
`

export {
  StyledFeedbackFormWrapper,
  StyledFeedbackFormInner,
  StyledFeedbackFormHeader,
  StyledFeedbackFieldHalf,
  StyledFeedbackFormAction,
  StyledFeedbackFormSentMsg,
}
