import React from 'react'
import PropTypes from 'prop-types'

import { StyledSelect, StyledSelectIcon, StyledSelectOption } from './styles'
import { StyledField, StyledFieldControl, StyledFieldLabel } from '../styles'
import ArrowDownIcon from '../../Icons/ArrowDownIcon'
import { FIELD_TYPES } from '../../../constants/fieldTypes'

const Select = ({
  fieldType,
  label,
  id,
  placeholder,
  options,
  validate,
  disabled,
  ...input
}) => (
  <StyledField disabled={disabled}>
    <StyledFieldControl fieldType={fieldType} validate={!!validate}>
      <StyledSelect id={id} {...input}>
        <StyledSelectOption initialValue hidden>
          {placeholder}
        </StyledSelectOption>
        {options.map(({ label, value }) => (
          <StyledSelectOption key={value} value={value}>
            {label}
          </StyledSelectOption>
        ))}
      </StyledSelect>
      <StyledFieldLabel htmlFor={id}>{label}</StyledFieldLabel>
      <StyledSelectIcon>
        <ArrowDownIcon />
      </StyledSelectIcon>
    </StyledFieldControl>
  </StyledField>
)

Select.defaultProps = {
  fieldType: FIELD_TYPES.light,
  disabled: false,
}

Select.propTypes = {
  fieldType: PropTypes.oneOf(Object.keys(FIELD_TYPES)),
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  placeholder: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  disabled: PropTypes.bool,
}

export default Select
