import styled from 'styled-components'

import Container from '../Container'
import Inner from '../Inner'
import media, { customMedia } from '../../common/MediaQueries'
import { StyledImage } from '../Image/styles'

const StyledOverlappedBox = styled(Container)`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledOverlappedBoxInner = styled(Inner)`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${customMedia.maxPhone} {
    margin-left: -1.875rem;
    margin-right: -1.875rem;
    width: calc(100% + 3.75rem);
  }

  @media ${media.phone} {
    flex-direction: row;
  }
`

const StyledOverlappedBoxContentCol = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 3.125rem;
  background: ${(props) => props.bgInner};

  @media ${customMedia.maxPhone} {
    padding: 1.875rem;
  }

  @media ${media.phone} {
    position: relative;
    z-index: 1;
    margin-top: 3rem;
    margin-bottom: 5rem;
    width: 50%;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      background: inherit;
      width: 50vw;
      height: 100%;
    }
  }

  @media ${media.tablet} {
    width: 47.6%;
    margin-top: 4.875rem;
    margin-bottom: 7rem;
  }
`

const StyledOverlappedBoxMediaCol = styled.div`
  @media ${media.phone} {
    width: 50%;
  }

  @media ${media.tablet} {
    width: calc(100% - 47.6%);
  }
`

const StyledOverlappedBoxContent = styled.div`
  @media ${media.phone} {
    padding: 5rem 3rem 5rem 0;
    max-width: 31rem;
  }
`

const StyledOverlappedBoxTitle = styled.div`
  margin-bottom: 1rem;

  @media ${media.phone} {
    margin-bottom: 1.875rem;
  }
`

const StyledOverlappedBoxIntro = styled.div`
  margin-bottom: 1.5rem;

  @media ${media.phone} {
    margin-bottom: 1.875rem;
  }
`

const StyledOverlappedBoxSubheading = styled.div`
  margin-bottom: 1.5rem;
  p {
    opacity: 0.8;
  }

  @media ${media.phone} {
    margin-bottom: 0.625rem;
    p {
      opacity: 1;
    }
  }
`

const StyledOverlappedBoxDesc = styled.div`
  p + p {
    margin-top: 1.5rem;
  }
`

const StyledOverlappedBoxMedia = styled.div`
  margin-left: -1.875rem;
  margin-right: -1.875rem;

  ${StyledImage} {
    max-height: 100vw;
  }

  @media ${media.phone} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 64.5%;
    height: 100%;
    margin: 0;

    ${StyledImage} {
      max-height: initial;
    }
  }
`

export {
  StyledOverlappedBox,
  StyledOverlappedBoxInner,
  StyledOverlappedBoxContentCol,
  StyledOverlappedBoxContent,
  StyledOverlappedBoxTitle,
  StyledOverlappedBoxIntro,
  StyledOverlappedBoxSubheading,
  StyledOverlappedBoxDesc,
  StyledOverlappedBoxMedia,
  StyledOverlappedBoxMediaCol,
}
