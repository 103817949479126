import { useMemo } from 'react'

import Input from '../../Field/Input/Input'
import Select from '../../Field/Select/Select'
import Textarea from '../../Field/Textarea/Textarea'
import RadioGroup from '../../Field//RadioGroup/RadioGroup'
import { StyledField } from '../styles'
import { requiredValidator } from '../../../common/validators'
import { makeInitialValues } from '../../../common/utils'

export const feedback = [
  {
    id: 'public-feedback-yes',
    label: 'Yes',
  },
  {
    id: 'public-feedback-no',
    label: 'No',
  },
]

export const useFormFields = (models, currentModel) =>
  useMemo(() => {
    const fields = [
      {
        title: 'Leave Feedback',
        fields: [
          {
            component: Input,
            id: 'fullName',
            label: 'Full Name',
            name: 'fullName',
            placeholder: 'Type your full name',
            validate: requiredValidator,
          },
          {
            component: Input,
            id: 'email',
            label: 'Email',
            type: 'email',
            name: 'email',
            placeholder: 'Type your email',
            validate: requiredValidator,
          },
          {
            component: Select,
            options: models,
            id: 'model',
            name: 'model',
            label: 'Select Model',
            placeholder: 'Choose Model',
            validate: requiredValidator,
            initialValue: currentModel,
          },
          {
            component: RadioGroup,
            title: 'Show this feedback to the public*',
            name: 'public-feedback',
            options: feedback,
            radioGroup: true,
          },
          {
            wrapper: StyledField,
            component: Textarea,
            id: 'feedback',
            label: 'Your Feedback',
            name: 'feedback',
            placeholder: 'Your feedback...',
            validate: requiredValidator,
          },
        ],
      },
    ]
    const initialValues = makeInitialValues(fields)

    return {
      fields,
      initialValues,
    }
  }, [models, currentModel])
