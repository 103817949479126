export const TWO_COLS_MEDIA_TYPES = {
  left: 'left',
  right: 'right',
}

export const LEFT_COL_THEME_TYPES = {
  dark: 'dark',
  light: 'light',
}

export const RIGHT_COL_THEME_TYPES = {
  dark: 'dark',
  light: 'light',
}
