import React from 'react'
import PropTypes from 'prop-types'

import { StyledMessage } from './styles'
import { Typo5 } from '../Typography/Typography'
import { MESSAGE_TYPES } from '../../constants/messageTypes'

const Message = ({ children, msgType }) => (
  <StyledMessage msgType={msgType}>
    <Typo5>{children}</Typo5>
  </StyledMessage>
)

export default Message

Message.defaultProps = {
  msgType: MESSAGE_TYPES.error,
}

Message.propTypes = {
  children: PropTypes.node.isRequired,
  msgType: PropTypes.oneOf(Object.keys(MESSAGE_TYPES)),
}
